import { createGlobalStyle } from 'styled-components';

export const DialogStyle = createGlobalStyle`
    
    .coupons-modal {
        display: flex;
        flex-direction: column;
        align-items: center;
        .code {
            display: inline-block;
            padding: 16px 32px;
            margin: 24px auto;
            border-radius: 8px;
            font-family: PingFang SC;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            text-align: center;
            letter-spacing: 0.5px;
            background: #F7F8FA;
            border-radius: 8px;
        }

        
    }

    
`;
