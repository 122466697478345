import { useState, useMemo } from 'react';
import ActivityApi from 'api/activity';
import { buriedPoint } from 'utils/log-buried-point';

const JOIN_TYPE = {
  login: 'login',
};

export const useDrawShare = ({ activityId, customerId = '', customerNo = '', joinType, onSharedFinishedCallBack }) => {
  const shareLinkUrl = useMemo(() => {
    return (!__isServer ? window.location.origin : '') + `/activity/lucky-draw?activityId=${activityId}`;
  }, [activityId]);

  const onTriggerShare = (method) => {
    buriedPoint('share', { method });
    if ((joinType === JOIN_TYPE.login && customerId) || (joinType !== JOIN_TYPE.login && customerNo)) {
      ActivityApi.addDrawActivityTickets({
        activityId,
        channel: 'activity_share',
        accountNo: customerNo,
      }).then(() => {
        onSharedFinishedCallBack && onSharedFinishedCallBack();
      });
    }
  };

  return {
    shareLinkUrl,
    onTriggerShare,
  };
};
