import React, { useEffect, useState } from 'react';
import { useThemeContext } from 'hook/global-theme';
import { Dialog, FormError, Input } from 'zent';
import Style, { DialogStyle } from './style';
import { useIntl } from 'react-intl';
import { useActivity } from './hooks';
import { useBindEmail } from 'hook/account/bindEmailHooks';
import { useDrawShare } from './hooks/use-draw-share';
import { ActivityStatus, DrawResultIntl, DrawResult } from 'const/activity';
import { diffRelativeDateTimeWithDay } from 'utils/datetime';
import Toast from 'cpn/toast';
import { getQueryString } from 'utils';
import { Icon } from 'components/base';
import Image from 'components/base/image';

import cn from 'classnames';
import Coupons from './components/coupons';
import CouponsModal from './components/couponsModal/index';
import NewButton from 'components/base/new-button';
import { ShareButtons } from 'components/page/share-buttons';

const defaultLogo = 'https://b.yzcdn.cn/path/to/cdn/dir/logo-default.png';
const LuckyDraw = (props) => {
  const { isMobile } = useThemeContext();
  const intl = useIntl();
  const { initialData: { kdtId, userInfo, shopLogoUrl } = {} } = props;

  const { customerNo, errorText, handleEmailChange, bindEmail } = useBindEmail({ intl });

  const { activityId, isEdit } = getQueryString(props.location.search);
  const [showShared, setShowShared] = useState(false);

  const {
    customerId,
    activitySetting = {}, // 活动设置
    activityTickets, // 抽奖次数
    bulkActivityNameList = [], // 中奖名单
    activityItems = [], // 奖品
    startDraw, // 抽奖函数
    drawResult = {}, // 中奖结果
    selectIndex, // 跑马灯当前选中项
    getDrawActivitySetting, // 获取抽奖活动详细
    showDrawModal, // 是否显示中奖弹窗
    setShowDrawModal,
    showCouponsModal, // 是否显示中奖的优惠券弹窗
    setShowCouponsModal,
    showThanksModal, // 是否显示谢谢惠顾弹窗
    setShowThanksModal,
    showEmailModal, // 是否显示绑定邮箱弹窗
    setShowEmailModal,
    setIsBindEmailSuccess, // 是否已经绑定邮箱成功
    showRecordModal,
    setShowRecordModal,
    activityRecord,
    getDrawActivityRecords, // 抽奖记录
    login,
    getDrawActivityTickets,
    setIsStrictDrawing, // 是否直接进行抽奖
  } = useActivity({ activityId, isEdit, customerNo, kdtId, userInfo, initialData: props.initialData });

  const joinType = activitySetting?.drawRule?.joinType;
  const { shareLinkUrl, onTriggerShare } = useDrawShare({
    activityId,
    customerId,
    customerNo,
    joinType,
    onSharedFinishedCallBack: getDrawActivityTickets,
  });
  const [couponsModalItem, setCouponsModalItem] = useState({});
  const { drawPage = {}, drawRule = {} } = activitySetting;

  // 中奖名单动效相关处理
  // 高度=数据条数*36-18
  const scrollHeight = bulkActivityNameList.length * 36 - 18;
  const scrollMinHeight = 6 * 36 - 18; // 超过6条就滚动
  const scrollTime = scrollHeight >= scrollMinHeight ? (scrollHeight - scrollMinHeight) / 20 : 0;

  const drawResultIntlObj = DrawResultIntl[drawResult?.result || DrawResult.Success];
  // 剩余时间动态计算
  const [timerObj, setTimerObj] = useState({});
  const getWithZero = (relativeTime) => {
    const newTime = {};
    // eslint-disable-next-line array-callback-return
    Object.keys(relativeTime).map((key) => {
      // 天不补充0
      if (key !== 'day') {
        newTime[key] = relativeTime?.[key] < 10 ? `0${relativeTime?.[key] ?? 0}` : `${relativeTime?.[key]}`;
      } else {
        newTime[key] = `${relativeTime?.[key]}`;
      }
    });
    return newTime;
  };

  useEffect(() => {
    const interval = activitySetting.startAt
      ? setInterval(() => {
          let timerObjTemp = (activitySetting?.startAt && diffRelativeDateTimeWithDay(activitySetting.startAt)) || {};
          timerObjTemp = getWithZero(timerObjTemp);
          setTimerObj(timerObjTemp);
          if (activitySetting.startAt <= new Date().getTime()) {
            clearInterval(interval);
            console.log('开始轮训获取活动信息');
            getDrawActivitySetting();
          }
        }, 1000)
      : null;

    return () => {
      // eslint-disable-next-line no-unused-expressions
      interval && clearInterval(interval);
    };
  }, [activitySetting.startAt]);
  // console.log('是否拿到数据', activitySetting, shopLogoUrl, new Date().getTime());
  return (
    <Style>
      <DialogStyle />
      <div
        className='luck-activity'
        style={{
          backgroundColor: drawPage?.backType === 'color' && drawPage.colorValue,
          backgroundImage:
            drawPage?.backType === 'image' && !!drawPage?.imageValue ? `url(${drawPage?.imageValue})` : 'none',
        }}
      >
        <div className='main'>
          <div className='draw-content'>
            <Image alt='background' className='coin-bg' src='https://b.yzcdn.cn/path/to/cdn/dir/coin-bg3.png' />
            {drawPage?.showLogo ? (
              <Image
                alt='background'
                className='logo'
                src={shopLogoUrl || defaultLogo}
                style={{ objectFit: 'contain', objectPosition: 'center' }}
              />
            ) : null}

            <div
              id='title_app'
              className='title'
              style={{ fontSize: `${((isMobile ? 40 : 80) * drawPage.titleSize) / 100}px` }}
              dangerouslySetInnerHTML={{
                __html: drawPage?.title,
              }}
            />

            <div
              className='sub-title'
              style={{ fontSize: `${((isMobile ? 10 : 20) * drawPage.subTitleSize) / 100}px` }}
              dangerouslySetInnerHTML={{
                __html: drawPage?.subTitle,
              }}
            />
            <Image
              alt='background'
              className='main-bg'
              src='https://b.yzcdn.cn/path/to/cdn/dir/main-bg3.png'
              style={{ objectFit: 'contain', objectPosition: 'center' }}
            />
            <div className='row'>
              <div className='luck-draw-container'>
                <div className='luck-draw'>
                  <div className='diejia'>
                    <div className='diejia2'>
                      <div className='turntable'>
                        <div className='list'>
                          {activityItems?.map((item, index) => {
                            return (
                              <div
                                key={item.itemName}
                                className={cn(`item list-${index + 1}`, {
                                  active: index === selectIndex,
                                })}
                              >
                                <Image
                                  alt='background'
                                  className='img'
                                  src={item.itemImageUrl}
                                  style={{
                                    objectFit: 'contain',
                                    objectPosition: 'center',
                                  }}
                                />

                                <div className='name'>{item.itemName}</div>
                              </div>
                            );
                          })}
                        </div>
                        {(isEdit || activitySetting.status === ActivityStatus.Scheduled) && (
                          <div
                            className={cn('start-btn', {
                              disabled: activityTickets === 0,
                            })}
                            onClick={startDraw}
                          >
                            <div className='go'>GO</div>
                            <div className='arrow' />
                          </div>
                        )}
                        {activitySetting.status === ActivityStatus.Waiting && (
                          <div className='start-btn disabled'>
                            <span className='wait-start'>
                              {intl.formatMessage({
                                id: 'discount_waiting',
                                defaultMessage: '待开始',
                              })}
                            </span>
                            <span className='time'>
                              {intl.formatMessage(
                                {
                                  id: '7c213bbc24b74e5f8fb03f501dbadb18',
                                },
                                {
                                  day: timerObj.day || 0,
                                  hour: timerObj.hour || 0,
                                  min: timerObj.min || 0,
                                  second: timerObj.second || 0,
                                },
                              )}
                            </span>
                          </div>
                        )}
                        {activitySetting.status === ActivityStatus.Expired && (
                          <div className='start-btn disabled'>
                            <span className='expired'>
                              {intl.formatMessage({
                                id: 'discount_expired',
                                defaultMessage: '已结束',
                              })}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className='draw-num'>
                        <span className='text'>
                          {intl.formatMessage({
                            id: '631a8d2ece044c6983019c2990da662f',
                            defaultMessage: '抽奖机会',
                          })}
                        </span>
                        <span className='num'>{typeof activityTickets === 'number' ? activityTickets : '?'}</span>
                      </div>
                      <div className='record-and-share'>
                        <div
                          className='record-btn'
                          onClick={() => {
                            // 未登陆跳转登陆页面
                            if (!customerId) {
                              if (drawRule?.joinType === 'login') {
                                // 登陆抽奖则让用户登陆
                                login();
                              } else {
                                // 订阅抽奖提示用户邮箱查看中奖
                                Toast.info(
                                  intl.formatMessage({
                                    id: '026cf1ed19fd4d3d8aaa0a68561299a2',
                                    description: '请前往邮箱查看中奖记录',
                                  }),
                                  800,
                                );
                              }
                            } else {
                              getDrawActivityRecords();
                            }
                          }}
                        >
                          {intl.formatMessage({
                            id: '4093ee61e8934dba85ed855d5a6da017',
                            defaultMessage: '中奖记录',
                          })}
                        </div>

                        <div
                          className='share-btn'
                          onClick={() => {
                            setShowShared(!showShared);
                          }}
                        >
                          <Icon className='iconchoujiangfenxiang' name='iconchoujiangfenxiang' />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='share-popover' style={{ display: showShared ? 'block' : 'none' }}>
                    <div className='label'>
                      {intl.formatMessage({
                        id: '53d9f2259cba4c748dda395a5a18e716',
                        defaultMessage: '分享给朋友',
                      })}
                    </div>
                    <ShareButtons
                      // 生成海报的参数
                      // postInfo={{
                      //     goodsId,
                      //     activityId,
                      // }}
                      showWeChat
                      shareThirdUrl={`activity/lucky-draw?activityId=${activityId}`}
                      copyUrl={shareLinkUrl}
                      customClass='lunk-draw-share-btns'
                      socialShareData={{}}
                      handleBuriedPoint={onTriggerShare}
                    />
                  </div>
                </div>
              </div>
              {((drawPage?.showNameList && bulkActivityNameList.length > 0) || drawPage?.description) && (
                <div className='section-container'>
                  {drawPage?.showNameList && bulkActivityNameList.length > 0 ? (
                    <div className='section'>
                      <div className='section-title'>
                        <div className='icon' />
                        <span className='text'>
                          {' '}
                          {intl.formatMessage({
                            id: '41cfe1d3e3f343a9b37c35943211885f',
                            defaultMessage: '中奖名单',
                          })}
                        </span>
                        <div className='icon' />
                      </div>
                      <div className='container' style={{ height: `${scrollHeight}px` }}>
                        <div className='section-content' style={{ animation: `scroll ${scrollTime}s linear infinite` }}>
                          {bulkActivityNameList.map((item, index) => {
                            return (
                              <div className='item' key={item.customerName + index}>
                                <span className='name'>{item.customerName}</span>
                                <span className='draw-name'>{item.itemName}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {drawPage?.description ? (
                    <div className='section'>
                      <div className='section-title'>
                        <div className='icon' />
                        <span className='text'>
                          {intl.formatMessage({
                            id: 'caeba54e1d9043ab972684a2b1aa29ec',
                            defaultMessage: '抽奖规则',
                          })}
                        </span>
                        <div className='icon' />
                      </div>
                      <div
                        className='section-content'
                        dangerouslySetInnerHTML={{
                          __html: drawPage?.description,
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          </div>

          {/* 显示中奖记录弹窗 */}
          <Dialog
            className='dialog record-modal'
            visible={showRecordModal}
            onClose={() => {
              setShowRecordModal(false);
            }}
            maskClosable={false}
            footer={null}
            title={intl.formatMessage({
              id: '4093ee61e8934dba85ed855d5a6da017',
              defaultMessage: '中奖记录',
            })}
          >
            {activityRecord.map((record, index) => {
              return (
                <Coupons
                  className='coupon'
                  key={index}
                  {...record}
                  onClick={() => {
                    setShowRecordModal(false);
                    setShowCouponsModal(true);
                    setCouponsModalItem(record);
                  }}
                />
              );
            })}
            {(!activityRecord || activityRecord.length === 0) && (
              <div className='text'>
                {intl.formatMessage({
                  id: 'store.domain.has.none_data',
                  defaultMessage: '暂无数据',
                })}
                ~
              </div>
            )}
          </Dialog>
          {/* 显示中奖弹窗 */}
          <Dialog
            className='dialog draw-modal'
            visible={showDrawModal}
            onClose={() => {
              setShowDrawModal(false);
            }}
            maskClosable={false}
            footer={null}
            title={intl.formatMessage({
              id: '5d0a8942418142e2b334f83262bbbf2d',
              defaultMessage: '恭喜你抽中',
            })}
          >
            <Coupons className='coupon' {...drawResult} />
            <NewButton
              className='btn'
              type='primary'
              size='large'
              onClick={() => {
                setShowDrawModal(false);
                setShowCouponsModal(true);
                setCouponsModalItem(drawResult);
              }}
            >
              {intl.formatMessage({
                id: 'd779e0682db94c74adcec5db1c06dd19',
                defaultMessage: '查看奖品',
              })}
            </NewButton>
          </Dialog>
          {/* 查看卡券弹窗 */}
          <CouponsModal
            className='dialog white coupons-modal'
            isOpened={showCouponsModal}
            {...couponsModalItem}
            history={props.history}
            onCancel={() => {
              setShowCouponsModal(false);
            }}
          />
          {/* 显示未中奖弹窗 */}
          <Dialog
            className='dialog white thanks-modal'
            visible={showThanksModal}
            onClose={() => {
              setShowThanksModal(false);
            }}
            maskClosable={false}
            footer={null}
            title={intl.formatMessage({
              id: '6fcbe7ec14524be794f1b632eb57ffc1',
              defaultMessage: '谢谢参与',
            })}
            style={{
              width: '460px',
              padding: 0,
            }}
          >
            <div className='sub-title'>
              {intl.formatMessage({
                id: drawResultIntlObj?.intlId,
                defaultMessage: drawResultIntlObj?.defaultMessage,
              })}
            </div>
            <Image
              alt='background'
              className='img'
              src='https://b.yzcdn.cn/path/to/cdn/dir/empty-box.png'
              style={{ objectFit: 'contain', objectPosition: 'center' }}
            />

            <NewButton
              className='btn'
              type='primary'
              size='large'
              onClick={() => {
                setShowThanksModal(false);
              }}
            >
              {intl.formatMessage({
                id: 'common_confirm',
                defaultMessage: '确定',
              })}
            </NewButton>
          </Dialog>
          {/* 订阅抽奖弹窗 */}
          <Dialog
            className='dialog white  email-modal'
            visible={showEmailModal}
            onClose={() => {
              setShowEmailModal(false);
            }}
            maskClosable={false}
            footer={null}
            title={intl.formatMessage({
              id: 'a26e7dd4c25a4a6e964e49cfa0d9000e',
              defaultMessage: '订阅抽奖',
            })}
            style={{
              width: '460px',
              padding: 0,
            }}
          >
            <div className='label'>
              {intl.formatMessage({
                id: '785a183a66954f2f9a0a72d1deaf7b29',
                defaultMessage: '订阅邮箱',
              })}
            </div>
            <Input
              className='email-input-box'
              value={customerNo}
              placeholder={intl.formatMessage({
                id: '3b1044df604f41d9b0f27eb6bac387d8',
                defaultMessage: '请输入您的邮箱号',
              })}
              onChange={handleEmailChange}
            />
            {errorText.customerNo && <FormError>{errorText.customerNo}</FormError>}
            <NewButton
              className='btn'
              type='primary'
              size='large'
              onClick={() => {
                bindEmail(() => {
                  setShowEmailModal(false);
                  setIsBindEmailSuccess(true);
                  setIsStrictDrawing(true);
                });
              }}
            >
              {intl.formatMessage({
                id: 'f68ee633d45c4dc7a52e30a1fd0cba2a',
                defaultMessage: '立即抽奖',
              })}
            </NewButton>
          </Dialog>
        </div>
      </div>
    </Style>
  );
};

export default LuckyDraw;
