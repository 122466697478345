/* eslint-disable */
import ActivityApi from 'api/activity';
import { useState, useEffect, useRef } from 'react';
import { DrawResult } from 'const/activity';
import { useIntl } from 'react-intl';
import Toast from 'cpn/toast';
import { useRootContext } from 'client/pages/context/root';

export const useActivity = ({ activityId, isEdit = false, customerNo = '', kdtId = '', userInfo, initialData }) => {
  const intl = useIntl();
  const { setLoginDialogInfo } = useRootContext();
  let { customerId: customerIdTemp } = userInfo || {};
  const [customerId, setCustomerId] = useState(customerIdTemp);
  let drawPage = initialData?.activityData?.drawPage;
  if (drawPage) {
    drawPage = {
      ...drawPage,
      ...JSON.parse(drawPage?.feature),
    };
  }
  const [activitySetting, setActivitySetting] = useState({ ...initialData?.activityData, drawPage });
  const [activityTickets, setActivityTickets] = useState();
  const [bulkActivityNameList, setBulkActivityNameList] = useState([]);
  const [activityItems, setActivityItems] = useState(new Array(8).fill({}));

  const [drawResult, setDrawResult] = useState({});
  const [isDrawing, setIsDrawing] = useState(false);
  const [selectIndex, setSelectIndex] = useState();

  const [activityRecord, setActivityRecord] = useState([]);
  const [showRecordModal, setShowRecordModal] = useState(false);
  const [showDrawModal, setShowDrawModal] = useState(false);
  const [showCouponsModal, setShowCouponsModal] = useState(false);
  const [showThanksModal, setShowThanksModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [isShowLogin, setIsShowLogin] = useState(false);
  const [isBindEmailSuccess, setIsBindEmailSuccess] = useState(false);

  const [isStrictDrawing, setIsStrictDrawing] = useState(false);
  // 异部函数拿不到最新值，用proxyRef中转
  const proxyRef = useRef();
  proxyRef.current = {
    drawResult,
    selectIndex,
  };
  //获取中奖记录
  const getDrawActivityRecords = () => {
    ActivityApi.getDrawActivityRecords({
      activityId,
      kdtId,
    }).then((data) => {
      setActivityRecord(data);
      if (data && data.length > 0) {
        setShowRecordModal(true);
      } else {
        Toast.info(
          intl.formatMessage({
            id: 'ea46310d44dd4ad0b9dc92d7b8403ced',
            description: '暂无中奖记录',
          }),
          800,
        );
      }
    });
  };
  //1.获取活动信息
  const getDrawActivitySetting = () => {
    const params = {
      activityId: activityId,
      kdtId,
    };
    ActivityApi.getDrawActivitySetting(params).then((data) => {
      data.drawPage = { ...data.drawPage, ...JSON.parse(data?.drawPage?.feature) };
      setActivitySetting(data);
      console.log('接口拿到数据', data, new Date());
    });
  };
  //获取中奖名单
  const getBulkActivityNameList = () => {
    const params = {
      activityId: activityId,
      kdtId,
    };
    ActivityApi.getBulkActivityNameList(params).then((data) => {
      setBulkActivityNameList(data);
    });
  };

  //获取抽奖物品列表
  const getDrawActivityItems = () => {
    const params = {
      activityId: activityId,
      kdtId,
    };
    ActivityApi.getDrawActivityItems(params).then((data) => {
      setActivityItems(getDrawActivityItemsPosition(data));
    });
  };
  // 根据拿到的抽奖物品来排列九宫格
  const getDrawActivityItemsPosition = (data) => {
    if (!data || data.length < 2) {
      return [];
    }
    let thanksItem;
    // 先把谢谢惠顾拿出来
    let tempData = data.filter((item) => {
      if (item.itemType === 'thanks') {
        thanksItem = item;
        return false;
      } else {
        return true;
      }
    });
    let tempDataIndex = 0;
    let thanksIndex = 0;
    let returnArray = [];
    for (let index = 0; index < 8; index++) {
      // 一个奖品都没配，全部放谢谢回顾
      // 或：如果在奇数位上，且谢谢回顾的数量没有超过，则插入谢谢回顾
      if (!tempData || tempData.length === 0 || (index % 2 === 1 && thanksIndex < 8 - tempData.length)) {
        returnArray.push(thanksItem);
        thanksIndex++;
      } else {
        // 不在基数位上，或者谢谢惠顾已经插入完成
        // 按顺序循环插入奖品，直到填满9宫格
        if (tempDataIndex >= tempData.length) {
          tempDataIndex = 0;
        }
        returnArray.push(tempData[tempDataIndex]);
        tempDataIndex++;
      }
    }
    return returnArray;
  };
  // 参与抽奖
  const joinDrawActivity = async () => {
    const params = {
      activityId: activityId,
      kdtId,
      accountNo: isBindEmailSuccess ? customerNo : '',
    };
    const res = await ActivityApi.joinDrawActivity(params);

    setDrawResult(res);
    // 重新获取抽奖次数
    getDrawActivityTickets();
  };
  const login = () => {
    // 此h5页面可以被内嵌app内，如果是在app内，唤起app的登陆流程

    // 调起我们自己的登陆弹窗
    setLoginDialogInfo({
      visible: true,
    });
  };
  // 开始抽奖逻辑
  const startDraw = async () => {
    if (isDrawing) {
      return;
    }
    // 根据活动类型，来判定用户是否具备条件

    if (activitySetting?.drawRule?.joinType === 'login') {
      // 登陆抽奖
      if (customerId) {
        // 有抽奖次数
        if (activityTickets) {
          // 已登陆直接抽奖
          setIsDrawing(true);
          await joinDrawActivity();
        }
      } else {
        // 未登陆弹窗登陆
        login();
      }
    } else {
      // 邮箱抽奖
      if (isBindEmailSuccess && customerNo) {
        // 有抽奖次数
        if (activityTickets) {
          setIsDrawing(true);
          await joinDrawActivity();
        }
      } else {
        // 订阅抽奖，永远弹窗输入邮箱框
        setShowEmailModal(true);
      }
    }
    // 重置是否直接抽奖的值
    setIsStrictDrawing(false);
  };
  // 开始抽奖，处理抽奖动效
  const drawAnimation = () => {
    if (!isDrawing) {
      return;
    }
    const SPACE_TIME_START = 20; // 跑马灯最开始切换的时间间隔
    const SPACE_TIME_ADD1 = 1; // 动画第一个阶段快速切换：每次切换增加的时间
    const SPACE_TIME_END1 = 65; // 动画第一个阶段结束的时间间隔
    const SPACE_TIME_ADD2 = 45; // 动画第二个阶段明显减速切换：每次切换增加的时间
    const SPACE_TIME_END2 = 400; // 动画第二个阶段结束的时间间隔
    let spaceTime = SPACE_TIME_START;

    let timer;
    function recursion() {
      const { drawResult, selectIndex } = proxyRef.current;
      if (timer) {
        clearTimeout(timer);
      }
      if (spaceTime > SPACE_TIME_END2 && drawResult && activityItems[selectIndex]?.itemId === drawResult.itemId) {
        // 找到中奖奖品，停止跑马灯

        timer = setTimeout(() => {
          setIsDrawing(false);
          // 弹窗提示中奖情况,在这里奖品只有折扣券
          if (drawResult.result === DrawResult.Success) {
            setShowDrawModal(true);
          } else {
            setShowThanksModal(true);
          }
        }, 100);
        return;
      } else {
        if (selectIndex < 7) {
          proxyRef.current.selectIndex = selectIndex + 1;
        } else {
          proxyRef.current.selectIndex = 0;
        }
        setSelectIndex(proxyRef.current.selectIndex);
        spaceTime = spaceTime + (spaceTime < SPACE_TIME_END1 ? SPACE_TIME_ADD1 : SPACE_TIME_ADD2);
        timer = setTimeout(() => {
          recursion();
        }, spaceTime);
      }
    }
    if (isDrawing) {
      // 开始跑马灯
      recursion();
    } else {
      if (timer) {
        clearTimeout(timer);
      }
    }
  };
  // 获取抽奖次数
  // go 是否获取次数后立马抽奖
  const getDrawActivityTickets = () => {
    const { joinType } = activitySetting?.drawRule || {};
    // 如果用户已登陆或者已绑定邮箱成功获取抽奖次数
    if ((joinType === 'login' && customerId) || (joinType !== 'login' && isBindEmailSuccess)) {
      const params = {
        activityId: activityId,
        kdtId,
        accountNo: isBindEmailSuccess ? customerNo : '',
      };
      ActivityApi.getDrawActivityTickets(params).then((data) => {
        setActivityTickets(data);
      });
    }
  };
  // 装修的逻辑处理
  const fitup = () => {
    window.onmessage = (e) => {
      const { data, origin } = e;
      // data.type:`EDIT_${装修模块的id}`
      if (data.type === `EDIT_lunk-draw` && origin === window.location.origin) {
        // 装修数据处理
        const content = JSON.parse(data?.content);
        setActivitySetting({ ...activityItems, drawPage: content });
        console.log('m端获取的装修数据', content);
      }
      // 设置默认的奖品
      setActivityItems([
        { itemImageUrl: 'https://b.yzcdn.cn/path/to/cdn/dir/coupons.png', itemName: '20% OFF' },
        { itemImageUrl: 'https://b.yzcdn.cn/path/to/cdn/dir/thanks-injoin.png', itemName: 'Thanks' },
        { itemImageUrl: 'https://b.yzcdn.cn/path/to/cdn/dir/coupons.png', itemName: '20% OFF' },
        { itemImageUrl: 'https://b.yzcdn.cn/path/to/cdn/dir/thanks-injoin.png', itemName: 'Thanks' },
        { itemImageUrl: 'https://b.yzcdn.cn/path/to/cdn/dir/coupons.png', itemName: '20% OFF' },
        { itemImageUrl: 'https://b.yzcdn.cn/path/to/cdn/dir/thanks-injoin.png', itemName: 'Thanks' },
        { itemImageUrl: 'https://b.yzcdn.cn/path/to/cdn/dir/coupons.png', itemName: '20% OFF' },
        { itemImageUrl: 'https://b.yzcdn.cn/path/to/cdn/dir/thanks-injoin.png', itemName: 'Thanks' },
      ]);
      setBulkActivityNameList([
        {
          customerName: 'Lsa103***',
          itemName: intl.formatMessage({
            id: 'dbba785b7e9f41aab17c03578807b0cf',
            defaultMessage: '获得300-1000优惠券',
          }),
        },
        {
          customerName: 'Lsa103***',
          itemName: intl.formatMessage({
            id: '17529b0b0ce34f6fa67c4d7d85305371',
            defaultMessage: '获得 Iphone XR',
          }),
        },
        {
          customerName: 'Lsa103***',
          itemName: intl.formatMessage({
            id: '94289464de78424994579bdc9ffd2bd4',
            defaultMessage: '获得视频VIP周卡',
          }),
        },
      ]);
    };
  };
  // 非装修逻辑处理
  const injoinStep = () => {
    //1.获取活动信息
    !initialData?.activityData && getDrawActivitySetting();
    //2.获取奖品
    getDrawActivityItems();
    //3.如果用户已登陆获取抽奖次数 ,移到useEffect
    // customerId && getDrawActivityTickets();
    //4.获取中奖名单
    getBulkActivityNameList();
  };
  // 直接进行抽奖的逻辑
  useEffect(() => {
    if (isStrictDrawing && activityTickets > 0) {
      startDraw();
    }
  }, [isStrictDrawing, activityTickets]);
  useEffect(() => {
    console.log('获取次数');
    getDrawActivityTickets();
  }, [customerId, activitySetting, isBindEmailSuccess]);
  useEffect(() => {
    setCustomerId(customerIdTemp);
  }, [customerIdTemp]);
  useEffect(() => {
    // 装修
    if (isEdit) {
      fitup();
    } else {
      if (kdtId) {
        injoinStep();
      }
    }
  }, [activityId, kdtId]);
  useEffect(() => {
    drawAnimation();
  }, [isDrawing]);

  return {
    customerId,
    activitySetting, // 活动设置
    activityTickets, // 抽奖次数
    bulkActivityNameList, // 中奖名单
    activityItems, // 奖品
    startDraw, // 抽奖函数
    drawResult, // 中奖结果
    isDrawing, // 是否正在抽奖
    selectIndex, // 跑马灯当前选中项
    getDrawActivityTickets,
    getDrawActivitySetting,

    showDrawModal, // 是否显示中奖弹窗
    setShowDrawModal,
    showCouponsModal, // 是否显示中奖的优惠券弹窗
    setShowCouponsModal,
    showThanksModal, // 是否显示谢谢惠顾弹窗
    isBindEmailSuccess,
    setIsBindEmailSuccess, // 是否已经绑定邮箱成功
    setShowThanksModal,
    showEmailModal, // 是否显示绑定邮箱弹窗
    setShowEmailModal,
    isShowLogin, // 是否显示登录弹窗
    setIsShowLogin,
    login,
    showRecordModal,
    setShowRecordModal,
    activityRecord,
    getDrawActivityRecords, // 抽奖记录
    setIsStrictDrawing, // 是否直接进行抽奖
  };
};
