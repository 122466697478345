import styled from 'styled-components';

export default styled.div`
  @media screen and (max-width: 768px) {
    .container {
      height: 72px !important;
      &::before,
      &::after {
        width: 8px !important;
        height: 8px !important;

        left: 60px !important;
        top: -4px !important;
      }
      &::after {
        bottom: -8px !important;
        top: unset !important;
      }
    }
    .left {
      margin: 0 12px !important;
      .img {
        width: 48px !important;
        height: 48px !important;
      }
    }
    .midden {
      .coupon-title {
        margin: 0px 0 8px !important;
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 22px !important;
      }
      .coupon-time {
        font-size: 13px !important;
        line-height: 18px !important;
        letter-spacing: 0.5px !important;
      }
    }
    .right {
      .btn {
        padding: 4px !important;
        margin: 0 2px !important;
      }
    }
  }
  .coupons {
    .container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 96px;
      /* 其他/卡片色 */

      background: #fff;
      border-radius: 8px;
      position: relative;
      &::before,
      &::after {
        content: '';
        width: 16px;
        height: 16px;
        background: linear-gradient(120.3deg, #fc6d30 0%, #ff4123 100.4%);
        //background: linear-gradient(120.3deg, #fc6d30 0%, #ff4123 100.4%);
        position: absolute;
        left: 84px;
        top: -8px;
        border-radius: 100%;
      }
      &::after {
        top: 100%;
        transform: translate(0, -50%);
      }
    }

    .left {
      margin: 0 24px 0 16px;
      .img {
        width: 64px;
        height: 64px;
      }
    }
    .midden {
      flex: 1;
      .coupon-title {
        text-shadow: none;
        text-align: left;
        margin: 0px 0 8px;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.5px;

        color: #333;
      }
      .coupon-time {
        text-align: left;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.5px;

        /* 文本/次要文案60% */

        color: rgba(51, 51, 51, 0.6);
      }
    }
    .right {
      .btn {
        margin: 0 8px;
        width: auto;
        height: 26px;
        padding: 9px 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(315deg, #ff5c00 0%, #ff900d 100%);
        border-radius: 50px;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.5px;
        color: #ffffff;
        .small {
          padding: 0;
          background-color: unset;
        }
        &::after {
          border: none;
        }
        &.disabled {
          /* 其他/背景色 */

          background: $color-body-bg;
          /* 文本/次要文案60% */

          color: $color-text-secondary;
        }
      }
    }
  }
`;
